// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataCreatePackageApplication: null,
    respDataCompany: null,
    respDataFranchise: null,
    respDataAppLication: {},
  },
  getters: {
  },
  mutations: {
    VIEW_APPLICATION(state, payload) {
      state.respDataAppLication = payload.data
    },
    GET(state, payload) {
      state.respData = payload
    },
    GET_CREATE_PACKAGE_APPLICATION(state, payload) {
      state.respDataCreatePackageApplication = payload
    },
    GET_COMPANY(state, payload) {
      state.respDataCompany = payload
    },
    GET_FRANCHISE(state, payload) {
      state.respDataFranchise = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/application/view/${item.id}`)
          .then(response => {
            commit('VIEW_APPLICATION', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/application/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/application/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/application/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    versionChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/application/versionChange', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addDay({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/application/addDay', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getCreatePackageApplication({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/application/getCreatePackageApplication?franchiseId=${item}`)
          .then(response => {
            commit('GET_CREATE_PACKAGE_APPLICATION', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getCompany({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/application/getCompany?franchiseId=${item}`)
          .then(response => {
            commit('GET_COMPANY', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getFranchise({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/root/application/getFranchise')
          .then(response => {
            commit('GET_FRANCHISE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getSetUpSystem({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/application/getSetUpSystem?id=${item.id}&type=${item.type}`)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
